
import { Style } from 'react-style-tag';

function Beginning()
{
  return (
    <div data-elementor-type="header" data-elementor-id="37"
         className="elementor elementor-37 elementor-location-header"
         data-elementor-post-type="elementor_library">

      <section className="elementor-section elementor-top-section elementor-element elementor-element-07eefc2 elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
               data-id="07eefc2" data-element_type="section"
               data-settings="{&quot;sticky&quot;:&quot;top&quot;,&quot;sticky_effects_offset&quot;:50,&quot;sticky_on&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;],&quot;sticky_offset&quot;:0}">
        <div className="elementor-container elementor-column-gap-no">
          <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-52c9c8f"
               data-id="52c9c8f" data-element_type="column">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-e6e5056 l-logo elementor-widget__width-auto elementor-widget elementor-widget-image"
                   data-id="e6e5056" data-element_type="widget" data-widget_type="image.default">
                <div className="elementor-widget-container">
                  <Style>{`
                    /*! elementor - v3.16.0 - 20-09-2023 */
                    .elementor-widget-image {
                      text-align: center
                    }

                    .elementor-widget-image a {
                      display: inline-block
                    }

                    .elementor-widget-image a img[src$=".svg"] {
                      width: 48px
                    }

                    .elementor-widget-image img {
                      vertical-align: middle;
                      display: inline-block
                    }
                  `}</Style>
                  <a href="https://digitalrocket.com">
                    <img width="213" height="194" src="./images/logo-white-2.svg"
                         className="attachment-full size-full wp-image-181"
                         alt="Digital Rocket Science company logo" />
                  </a>
                </div>
              </div>
              <div className="elementor-element elementor-element-0585bf6 d-logo elementor-widget__width-auto elementor-widget elementor-widget-image"
                   data-id="0585bf6" data-element_type="widget" data-widget_type="image.default">
                <div className="elementor-widget-container">
                  <a href="https://digitalrocket.com">
                    <img width="219" height="200" src="./images/logo-01.svg"
                         className="attachment-full size-full wp-image-140" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-834a62c"
               data-id="834a62c" data-element_type="column">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-8ceaea1 elementor-widget__width-auto elementor-nav-menu--stretch elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                   data-id="8ceaea1" data-element_type="widget"
                   data-settings="{&quot;full_width&quot;:&quot;stretch&quot;,&quot;layout&quot;:&quot;horizontal&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;i className=\&quot;fas fa-caret-down\&quot;&gt;&lt;\/i&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;},&quot;toggle&quot;:&quot;burger&quot;}"
                   data-widget_type="nav-menu.default">
                <div className="elementor-widget-container">
                  <link rel="stylesheet" href="./css/widget-nav-menu.min.css" />
                  <nav className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none">
                    <ul id="menu-1-8ceaea1" className="elementor-nav-menu">
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-102">
                        <a href="#about-us" className="elementor-item elementor-item-anchor">
                          About us
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-103">
                        <a href="#education-products"
                           className="elementor-item elementor-item-anchor">
                          Education Products
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-228">
                        <a href="#services" className="elementor-item elementor-item-anchor">
                          Services
                        </a>
                      </li>
                      <li className="cta-contact menu-item menu-item-type-custom menu-item-object-custom menu-item-129">
                        <a href="#contact-us" className="elementor-item elementor-item-anchor">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </nav>
                  <div className="elementor-menu-toggle" role="button" tabIndex="0"
                       aria-label="Menu Toggle" aria-expanded="false">
                    <i aria-hidden="true" role="presentation"
                       className="elementor-menu-toggle__icon--open eicon-menu-bar"></i>
                    <i aria-hidden="true" role="presentation"
                       className="elementor-menu-toggle__icon--close eicon-close"></i>
                    <span className="elementor-screen-only">Menu</span>
                  </div>
                  <nav className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                       aria-hidden="true">
                    <ul id="menu-2-8ceaea1" className="elementor-nav-menu">
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-102">
                        <a href="#about-us" className="elementor-item elementor-item-anchor"
                           tabIndex="-1">
                          About us
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-103">
                        <a href="#education-products" tabIndex="-1"
                           className="elementor-item elementor-item-anchor">
                          Education Products
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-228">
                        <a href="#services" className="elementor-item elementor-item-anchor"
                           tabIndex="-1">
                          Services
                        </a>
                      </li>
                      <li className="cta-contact menu-item menu-item-type-custom menu-item-object-custom menu-item-129">
                        <a href="#contact-us" className="elementor-item elementor-item-anchor"
                           tabIndex="-1">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="elementor-element elementor-element-13ecc63 elementor-widget__width-auto agendar elementor-align-right elementor-hidden-mobile elementor-widget elementor-widget-button"
                   data-id="13ecc63" data-element_type="widget"
                   data-widget_type="button.default">
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a className="elementor-button elementor-button-link elementor-size-sm"
                       href="#contact-us">
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-text">
                          Contact Us
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Beginning;
