
function Rocket()
{
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-1f45f0b1 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
             data-id="1f45f0b1" data-element_type="section">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-55292f8d"
             data-id="55292f8d" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-13e269fe elementor-widget elementor-widget-image"
                 data-id="13e269fe" data-element_type="widget" data-widget_type="image.default">
              <div className="elementor-widget-container">
                <img decoding="async" loading="lazy" width="1086" height="840"
                     alt="Student's creativity and knowledge is greatly expanded through the use of Digital Rocket Science products"
                     className="attachment-full size-full wp-image-123" src="./images/image-4.jpg"
                     srcSet="./images/image-4.jpg 1086w, ./images/image-4-300x232.jpg 300w, ./images/image-4-1024x792.jpg 1024w, ./images/image-4-768x594.jpg 768w"
                     sizes="(max-width: 1086px) 100vw, 1086px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Rocket;
