
function Footer()
{
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer data-elementor-type="footer" data-elementor-id="95"
            className="elementor elementor-95 elementor-location-footer"
            data-elementor-post-type="elementor_library">
      <section className="elementor-section elementor-top-section elementor-element elementor-element-aeaf975 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
               data-id="aeaf975" data-element_type="section">
        <div className="elementor-container elementor-column-gap-custom">
          <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b488687"
               data-id="b488687" data-element_type="column">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-6307e57 elementor-widget elementor-widget-image"
                   data-id="6307e57" data-element_type="widget" data-widget_type="image.default">
                <div className="elementor-widget-container">
                  <a href="https://digitalrocket.com">
                    <img width="219" height="200" src="./images/logo-01.svg" alt="Digital Rocket Science company logo"
                         className="attachment-full size-full wp-image-140" loading="lazy" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-be7aa14"
               data-id="be7aa14" data-element_type="column">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-9ba74ff elementor-widget elementor-widget-heading"
                   data-id="9ba74ff" data-element_type="widget" data-widget_type="heading.default">
                <div className="elementor-widget-container">
                  <span className="elementor-heading-title elementor-size-default">
                    { year } © Digital Rocket Science &nbsp; | &nbsp; All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
