
function Services()
{
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-cf5e76a elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
             data-id="cf5e76a" data-element_type="section" id="services">
      <div className="elementor-container elementor-column-gap-custom">
        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d7b62e1"
             data-id="d7b62e1" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-9c7ec25 elementor-widget__width-auto elementor-absolute animated-slow elementor-view-default elementor-invisible elementor-widget elementor-widget-icon"
                 data-id="9c7ec25" data-element_type="widget"
                 data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:500,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateX_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateX_direction&quot;:&quot;negative&quot;,&quot;motion_fx_translateX_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:2,&quot;sizes&quot;:[]},&quot;motion_fx_translateX_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:50,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}"
                 data-widget_type="icon.default">
              <div className="elementor-widget-container">
                <div className="elementor-icon-wrapper">
                  <div className="elementor-icon">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         id="afb6f239-ddfa-4317-a8c2-6e12c8554240" data-name="Layer 1"
                         width="165.953" height="115.814" viewBox="0 0 165.953 115.814">
                      <circle cx="1.5" cy="1.5" r="1.5"></circle>
                      <circle cx="1.5" cy="14.0352" r="1.5"></circle>
                      <circle cx="1.5" cy="26.5684" r="1.5"></circle>
                      <circle cx="1.5" cy="39.1035" r="1.5"></circle>
                      <circle cx="1.5" cy="51.6406" r="1.5"></circle>
                      <circle cx="1.5" cy="64.1758" r="1.5"></circle>
                      <circle cx="1.5" cy="76.709" r="1.5"></circle>
                      <circle cx="1.5" cy="89.2441" r="1.5"></circle>
                      <circle cx="1.5" cy="101.779" r="1.5"></circle>
                      <circle cx="1.5" cy="114.314" r="1.5"></circle>
                      <circle cx="14.0312" cy="1.5" r="1.5"></circle>
                      <circle cx="14.0312" cy="14.0352" r="1.5"></circle>
                      <circle cx="14.0312" cy="26.5684" r="1.5"></circle>
                      <circle cx="14.0312" cy="39.1035" r="1.5"></circle>
                      <circle cx="14.0312" cy="51.6406" r="1.5"></circle>
                      <circle cx="14.0312" cy="64.1758" r="1.5"></circle>
                      <circle cx="14.0312" cy="76.709" r="1.5"></circle>
                      <circle cx="14.0312" cy="89.2441" r="1.5"></circle>
                      <circle cx="14.0312" cy="101.779" r="1.5"></circle>
                      <circle cx="14.0312" cy="114.314" r="1.5"></circle>
                      <circle cx="26.5664" cy="1.5" r="1.5"></circle>
                      <circle cx="26.5664" cy="14.0352" r="1.5"></circle>
                      <circle cx="26.5664" cy="26.5684" r="1.5"></circle>
                      <circle cx="26.5664" cy="39.1035" r="1.5"></circle>
                      <circle cx="26.5664" cy="51.6406" r="1.5"></circle>
                      <circle cx="26.5664" cy="64.1758" r="1.5"></circle>
                      <circle cx="26.5664" cy="76.709" r="1.5"></circle>
                      <circle cx="26.5664" cy="89.2441" r="1.5"></circle>
                      <circle cx="26.5664" cy="101.779" r="1.5"></circle>
                      <circle cx="26.5664" cy="114.314" r="1.5"></circle>
                      <circle cx="39.1016" cy="1.5" r="1.5"></circle>
                      <circle cx="39.1016" cy="14.0352" r="1.5"></circle>
                      <circle cx="39.1016" cy="26.5684" r="1.5"></circle>
                      <circle cx="39.1016" cy="39.1035" r="1.5"></circle>
                      <circle cx="39.1016" cy="51.6406" r="1.5"></circle>
                      <circle cx="39.1016" cy="64.1758" r="1.5"></circle>
                      <circle cx="39.1016" cy="76.709" r="1.5"></circle>
                      <circle cx="39.1016" cy="89.2441" r="1.5"></circle>
                      <circle cx="39.1016" cy="101.779" r="1.5"></circle>
                      <circle cx="39.1016" cy="114.314" r="1.5"></circle>
                      <circle cx="51.6367" cy="1.5" r="1.5"></circle>
                      <circle cx="51.6367" cy="14.0352" r="1.5"></circle>
                      <circle cx="51.6367" cy="26.5684" r="1.5"></circle>
                      <circle cx="51.6367" cy="39.1035" r="1.5"></circle>
                      <circle cx="51.6367" cy="51.6406" r="1.5"></circle>
                      <circle cx="51.6367" cy="64.1758" r="1.5"></circle>
                      <circle cx="51.6367" cy="76.709" r="1.5"></circle>
                      <circle cx="51.6367" cy="89.2441" r="1.5"></circle>
                      <circle cx="51.6367" cy="101.779" r="1.5"></circle>
                      <circle cx="51.6367" cy="114.314" r="1.5"></circle>
                      <circle cx="64.1719" cy="1.5" r="1.5"></circle>
                      <circle cx="64.1719" cy="14.0352" r="1.5"></circle>
                      <circle cx="64.1719" cy="26.5684" r="1.5"></circle>
                      <circle cx="64.1719" cy="39.1035" r="1.5"></circle>
                      <circle cx="64.1719" cy="51.6406" r="1.5"></circle>
                      <circle cx="64.1719" cy="64.1758" r="1.5"></circle>
                      <circle cx="64.1719" cy="76.709" r="1.5"></circle>
                      <circle cx="64.1719" cy="89.2441" r="1.5"></circle>
                      <circle cx="64.1719" cy="101.779" r="1.5"></circle>
                      <circle cx="64.1719" cy="114.314" r="1.5"></circle>
                      <circle cx="76.707" cy="1.5" r="1.5"></circle>
                      <circle cx="76.707" cy="14.0352" r="1.5"></circle>
                      <circle cx="76.707" cy="26.5684" r="1.5"></circle>
                      <circle cx="76.707" cy="39.1035" r="1.5"></circle>
                      <circle cx="76.707" cy="51.6406" r="1.5"></circle>
                      <circle cx="76.707" cy="64.1758" r="1.5"></circle>
                      <circle cx="76.707" cy="76.709" r="1.5"></circle>
                      <circle cx="76.707" cy="89.2441" r="1.5"></circle>
                      <circle cx="76.707" cy="101.779" r="1.5"></circle>
                      <circle cx="76.707" cy="114.314" r="1.5"></circle>
                      <circle cx="89.2461" cy="1.5" r="1.5"></circle>
                      <circle cx="89.2461" cy="14.0352" r="1.5"></circle>
                      <circle cx="89.2461" cy="26.5684" r="1.5"></circle>
                      <circle cx="89.2461" cy="39.1035" r="1.5"></circle>
                      <circle cx="89.2461" cy="51.6406" r="1.5"></circle>
                      <circle cx="89.2461" cy="64.1758" r="1.5"></circle>
                      <circle cx="89.2461" cy="76.709" r="1.5"></circle>
                      <circle cx="89.2461" cy="89.2441" r="1.5"></circle>
                      <circle cx="89.2461" cy="101.779" r="1.5"></circle>
                      <circle cx="89.2461" cy="114.314" r="1.5"></circle>
                      <circle cx="101.777" cy="1.5" r="1.5"></circle>
                      <circle cx="101.777" cy="14.0352" r="1.5"></circle>
                      <circle cx="101.777" cy="26.5684" r="1.5"></circle>
                      <circle cx="101.777" cy="39.1035" r="1.5"></circle>
                      <circle cx="101.777" cy="51.6406" r="1.5"></circle>
                      <circle cx="101.777" cy="64.1758" r="1.5"></circle>
                      <circle cx="101.777" cy="76.709" r="1.5"></circle>
                      <circle cx="101.777" cy="89.2441" r="1.5"></circle>
                      <circle cx="101.777" cy="101.779" r="1.5"></circle>
                      <circle cx="101.777" cy="114.314" r="1.5"></circle>
                      <circle cx="114.312" cy="1.5" r="1.5"></circle>
                      <circle cx="114.312" cy="14.0352" r="1.5"></circle>
                      <circle cx="114.312" cy="26.5684" r="1.5"></circle>
                      <circle cx="114.312" cy="39.1035" r="1.5"></circle>
                      <circle cx="114.312" cy="51.6406" r="1.5"></circle>
                      <circle cx="114.312" cy="64.1758" r="1.5"></circle>
                      <circle cx="114.312" cy="76.709" r="1.5"></circle>
                      <circle cx="114.312" cy="89.2441" r="1.5"></circle>
                      <circle cx="114.312" cy="101.779" r="1.5"></circle>
                      <circle cx="114.312" cy="114.314" r="1.5"></circle>
                      <circle cx="126.848" cy="1.5" r="1.5"></circle>
                      <circle cx="126.848" cy="14.0352" r="1.5"></circle>
                      <circle cx="126.848" cy="26.5684" r="1.5"></circle>
                      <circle cx="126.848" cy="39.1035" r="1.5"></circle>
                      <circle cx="126.848" cy="51.6406" r="1.5"></circle>
                      <circle cx="126.848" cy="64.1758" r="1.5"></circle>
                      <circle cx="126.848" cy="76.709" r="1.5"></circle>
                      <circle cx="126.848" cy="89.2441" r="1.5"></circle>
                      <circle cx="126.848" cy="101.779" r="1.5"></circle>
                      <circle cx="126.848" cy="114.314" r="1.5"></circle>
                      <circle cx="139.383" cy="1.5" r="1.5"></circle>
                      <circle cx="139.383" cy="14.0352" r="1.5"></circle>
                      <circle cx="139.383" cy="26.5684" r="1.5"></circle>
                      <circle cx="139.383" cy="39.1035" r="1.5"></circle>
                      <circle cx="139.383" cy="51.6406" r="1.5"></circle>
                      <circle cx="139.383" cy="64.1758" r="1.5"></circle>
                      <circle cx="139.383" cy="76.709" r="1.5"></circle>
                      <circle cx="139.383" cy="89.2441" r="1.5"></circle>
                      <circle cx="139.383" cy="101.779" r="1.5"></circle>
                      <circle cx="139.383" cy="114.314" r="1.5"></circle>
                      <circle cx="151.918" cy="1.5" r="1.5"></circle>
                      <circle cx="151.918" cy="14.0352" r="1.5"></circle>
                      <circle cx="151.918" cy="26.5684" r="1.5"></circle>
                      <circle cx="151.918" cy="39.1035" r="1.5"></circle>
                      <circle cx="151.918" cy="51.6406" r="1.5"></circle>
                      <circle cx="151.918" cy="64.1758" r="1.5"></circle>
                      <circle cx="151.918" cy="76.709" r="1.5"></circle>
                      <circle cx="151.918" cy="89.2441" r="1.5"></circle>
                      <circle cx="151.918" cy="101.779" r="1.5"></circle>
                      <circle cx="151.918" cy="114.314" r="1.5"></circle>
                      <circle cx="164.453" cy="1.5" r="1.5"></circle>
                      <circle cx="164.453" cy="14.0352" r="1.5"></circle>
                      <circle cx="164.453" cy="26.5684" r="1.5"></circle>
                      <circle cx="164.453" cy="39.1035" r="1.5"></circle>
                      <circle cx="164.453" cy="51.6406" r="1.5"></circle>
                      <circle cx="164.453" cy="64.1758" r="1.5"></circle>
                      <circle cx="164.453" cy="76.709" r="1.5"></circle>
                      <circle cx="164.453" cy="89.2441" r="1.5"></circle>
                      <circle cx="164.453" cy="101.779" r="1.5"></circle>
                      <circle cx="164.453" cy="114.314" r="1.5"></circle>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-d0f9430 elementor-widget__width-initial elementor-absolute animated-slow elementor-view-default elementor-invisible elementor-widget elementor-widget-icon"
                 data-id="d0f9430" data-element_type="widget"
                 data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:400,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateX_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateX_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;motion_fx_translateX_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:65,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}"
                 data-widget_type="icon.default">
              <div className="elementor-widget-container">
                <div className="elementor-icon-wrapper">
                  <div className="elementor-icon">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         id="afb6f239-ddfa-4317-a8c2-6e12c8554240" data-name="Layer 1"
                         width="165.953" height="115.814" viewBox="0 0 165.953 115.814">
                      <circle cx="1.5" cy="1.5" r="1.5"></circle>
                      <circle cx="1.5" cy="14.0352" r="1.5"></circle>
                      <circle cx="1.5" cy="26.5684" r="1.5"></circle>
                      <circle cx="1.5" cy="39.1035" r="1.5"></circle>
                      <circle cx="1.5" cy="51.6406" r="1.5"></circle>
                      <circle cx="1.5" cy="64.1758" r="1.5"></circle>
                      <circle cx="1.5" cy="76.709" r="1.5"></circle>
                      <circle cx="1.5" cy="89.2441" r="1.5"></circle>
                      <circle cx="1.5" cy="101.779" r="1.5"></circle>
                      <circle cx="1.5" cy="114.314" r="1.5"></circle>
                      <circle cx="14.0312" cy="1.5" r="1.5"></circle>
                      <circle cx="14.0312" cy="14.0352" r="1.5"></circle>
                      <circle cx="14.0312" cy="26.5684" r="1.5"></circle>
                      <circle cx="14.0312" cy="39.1035" r="1.5"></circle>
                      <circle cx="14.0312" cy="51.6406" r="1.5"></circle>
                      <circle cx="14.0312" cy="64.1758" r="1.5"></circle>
                      <circle cx="14.0312" cy="76.709" r="1.5"></circle>
                      <circle cx="14.0312" cy="89.2441" r="1.5"></circle>
                      <circle cx="14.0312" cy="101.779" r="1.5"></circle>
                      <circle cx="14.0312" cy="114.314" r="1.5"></circle>
                      <circle cx="26.5664" cy="1.5" r="1.5"></circle>
                      <circle cx="26.5664" cy="14.0352" r="1.5"></circle>
                      <circle cx="26.5664" cy="26.5684" r="1.5"></circle>
                      <circle cx="26.5664" cy="39.1035" r="1.5"></circle>
                      <circle cx="26.5664" cy="51.6406" r="1.5"></circle>
                      <circle cx="26.5664" cy="64.1758" r="1.5"></circle>
                      <circle cx="26.5664" cy="76.709" r="1.5"></circle>
                      <circle cx="26.5664" cy="89.2441" r="1.5"></circle>
                      <circle cx="26.5664" cy="101.779" r="1.5"></circle>
                      <circle cx="26.5664" cy="114.314" r="1.5"></circle>
                      <circle cx="39.1016" cy="1.5" r="1.5"></circle>
                      <circle cx="39.1016" cy="14.0352" r="1.5"></circle>
                      <circle cx="39.1016" cy="26.5684" r="1.5"></circle>
                      <circle cx="39.1016" cy="39.1035" r="1.5"></circle>
                      <circle cx="39.1016" cy="51.6406" r="1.5"></circle>
                      <circle cx="39.1016" cy="64.1758" r="1.5"></circle>
                      <circle cx="39.1016" cy="76.709" r="1.5"></circle>
                      <circle cx="39.1016" cy="89.2441" r="1.5"></circle>
                      <circle cx="39.1016" cy="101.779" r="1.5"></circle>
                      <circle cx="39.1016" cy="114.314" r="1.5"></circle>
                      <circle cx="51.6367" cy="1.5" r="1.5"></circle>
                      <circle cx="51.6367" cy="14.0352" r="1.5"></circle>
                      <circle cx="51.6367" cy="26.5684" r="1.5"></circle>
                      <circle cx="51.6367" cy="39.1035" r="1.5"></circle>
                      <circle cx="51.6367" cy="51.6406" r="1.5"></circle>
                      <circle cx="51.6367" cy="64.1758" r="1.5"></circle>
                      <circle cx="51.6367" cy="76.709" r="1.5"></circle>
                      <circle cx="51.6367" cy="89.2441" r="1.5"></circle>
                      <circle cx="51.6367" cy="101.779" r="1.5"></circle>
                      <circle cx="51.6367" cy="114.314" r="1.5"></circle>
                      <circle cx="64.1719" cy="1.5" r="1.5"></circle>
                      <circle cx="64.1719" cy="14.0352" r="1.5"></circle>
                      <circle cx="64.1719" cy="26.5684" r="1.5"></circle>
                      <circle cx="64.1719" cy="39.1035" r="1.5"></circle>
                      <circle cx="64.1719" cy="51.6406" r="1.5"></circle>
                      <circle cx="64.1719" cy="64.1758" r="1.5"></circle>
                      <circle cx="64.1719" cy="76.709" r="1.5"></circle>
                      <circle cx="64.1719" cy="89.2441" r="1.5"></circle>
                      <circle cx="64.1719" cy="101.779" r="1.5"></circle>
                      <circle cx="64.1719" cy="114.314" r="1.5"></circle>
                      <circle cx="76.707" cy="1.5" r="1.5"></circle>
                      <circle cx="76.707" cy="14.0352" r="1.5"></circle>
                      <circle cx="76.707" cy="26.5684" r="1.5"></circle>
                      <circle cx="76.707" cy="39.1035" r="1.5"></circle>
                      <circle cx="76.707" cy="51.6406" r="1.5"></circle>
                      <circle cx="76.707" cy="64.1758" r="1.5"></circle>
                      <circle cx="76.707" cy="76.709" r="1.5"></circle>
                      <circle cx="76.707" cy="89.2441" r="1.5"></circle>
                      <circle cx="76.707" cy="101.779" r="1.5"></circle>
                      <circle cx="76.707" cy="114.314" r="1.5"></circle>
                      <circle cx="89.2461" cy="1.5" r="1.5"></circle>
                      <circle cx="89.2461" cy="14.0352" r="1.5"></circle>
                      <circle cx="89.2461" cy="26.5684" r="1.5"></circle>
                      <circle cx="89.2461" cy="39.1035" r="1.5"></circle>
                      <circle cx="89.2461" cy="51.6406" r="1.5"></circle>
                      <circle cx="89.2461" cy="64.1758" r="1.5"></circle>
                      <circle cx="89.2461" cy="76.709" r="1.5"></circle>
                      <circle cx="89.2461" cy="89.2441" r="1.5"></circle>
                      <circle cx="89.2461" cy="101.779" r="1.5"></circle>
                      <circle cx="89.2461" cy="114.314" r="1.5"></circle>
                      <circle cx="101.777" cy="1.5" r="1.5"></circle>
                      <circle cx="101.777" cy="14.0352" r="1.5"></circle>
                      <circle cx="101.777" cy="26.5684" r="1.5"></circle>
                      <circle cx="101.777" cy="39.1035" r="1.5"></circle>
                      <circle cx="101.777" cy="51.6406" r="1.5"></circle>
                      <circle cx="101.777" cy="64.1758" r="1.5"></circle>
                      <circle cx="101.777" cy="76.709" r="1.5"></circle>
                      <circle cx="101.777" cy="89.2441" r="1.5"></circle>
                      <circle cx="101.777" cy="101.779" r="1.5"></circle>
                      <circle cx="101.777" cy="114.314" r="1.5"></circle>
                      <circle cx="114.312" cy="1.5" r="1.5"></circle>
                      <circle cx="114.312" cy="14.0352" r="1.5"></circle>
                      <circle cx="114.312" cy="26.5684" r="1.5"></circle>
                      <circle cx="114.312" cy="39.1035" r="1.5"></circle>
                      <circle cx="114.312" cy="51.6406" r="1.5"></circle>
                      <circle cx="114.312" cy="64.1758" r="1.5"></circle>
                      <circle cx="114.312" cy="76.709" r="1.5"></circle>
                      <circle cx="114.312" cy="89.2441" r="1.5"></circle>
                      <circle cx="114.312" cy="101.779" r="1.5"></circle>
                      <circle cx="114.312" cy="114.314" r="1.5"></circle>
                      <circle cx="126.848" cy="1.5" r="1.5"></circle>
                      <circle cx="126.848" cy="14.0352" r="1.5"></circle>
                      <circle cx="126.848" cy="26.5684" r="1.5"></circle>
                      <circle cx="126.848" cy="39.1035" r="1.5"></circle>
                      <circle cx="126.848" cy="51.6406" r="1.5"></circle>
                      <circle cx="126.848" cy="64.1758" r="1.5"></circle>
                      <circle cx="126.848" cy="76.709" r="1.5"></circle>
                      <circle cx="126.848" cy="89.2441" r="1.5"></circle>
                      <circle cx="126.848" cy="101.779" r="1.5"></circle>
                      <circle cx="126.848" cy="114.314" r="1.5"></circle>
                      <circle cx="139.383" cy="1.5" r="1.5"></circle>
                      <circle cx="139.383" cy="14.0352" r="1.5"></circle>
                      <circle cx="139.383" cy="26.5684" r="1.5"></circle>
                      <circle cx="139.383" cy="39.1035" r="1.5"></circle>
                      <circle cx="139.383" cy="51.6406" r="1.5"></circle>
                      <circle cx="139.383" cy="64.1758" r="1.5"></circle>
                      <circle cx="139.383" cy="76.709" r="1.5"></circle>
                      <circle cx="139.383" cy="89.2441" r="1.5"></circle>
                      <circle cx="139.383" cy="101.779" r="1.5"></circle>
                      <circle cx="139.383" cy="114.314" r="1.5"></circle>
                      <circle cx="151.918" cy="1.5" r="1.5"></circle>
                      <circle cx="151.918" cy="14.0352" r="1.5"></circle>
                      <circle cx="151.918" cy="26.5684" r="1.5"></circle>
                      <circle cx="151.918" cy="39.1035" r="1.5"></circle>
                      <circle cx="151.918" cy="51.6406" r="1.5"></circle>
                      <circle cx="151.918" cy="64.1758" r="1.5"></circle>
                      <circle cx="151.918" cy="76.709" r="1.5"></circle>
                      <circle cx="151.918" cy="89.2441" r="1.5"></circle>
                      <circle cx="151.918" cy="101.779" r="1.5"></circle>
                      <circle cx="151.918" cy="114.314" r="1.5"></circle>
                      <circle cx="164.453" cy="1.5" r="1.5"></circle>
                      <circle cx="164.453" cy="14.0352" r="1.5"></circle>
                      <circle cx="164.453" cy="26.5684" r="1.5"></circle>
                      <circle cx="164.453" cy="39.1035" r="1.5"></circle>
                      <circle cx="164.453" cy="51.6406" r="1.5"></circle>
                      <circle cx="164.453" cy="64.1758" r="1.5"></circle>
                      <circle cx="164.453" cy="76.709" r="1.5"></circle>
                      <circle cx="164.453" cy="89.2441" r="1.5"></circle>
                      <circle cx="164.453" cy="101.779" r="1.5"></circle>
                      <circle cx="164.453" cy="114.314" r="1.5"></circle>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-4a0d016 elementor-widget-mobile__width-initial elementor-invisible elementor-widget elementor-widget-image"
                 data-id="4a0d016" data-element_type="widget"
                 data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}"
                 data-widget_type="image.default">
              <div className="elementor-widget-container">
                <img decoding="async" loading="lazy" width="500" height="540"
                     src="./images/rocket-sled-3.jpg"
                     className="attachment-full size-full wp-image-261"
                     alt="Digital Rocket Science empowers teachers and educators to great heights"
                     srcSet="./images/rocket-sled-3.jpg 500w, ./images/rocket-sled-3-278x300.jpg 278w"
                     sizes="(max-width: 500px) 100vw, 500px" />
              </div>
            </div>
          </div>
        </div>
        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5c42e31"
             data-id="5c42e31" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-a3b91a6 elementor-widget-divider--separator-type-pattern elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                 data-id="a3b91a6" data-element_type="widget" data-widget_type="divider.default">
              <div className="elementor-widget-container">
                <div className="elementor-divider my-divider-pattern-4">
                  <span className="elementor-divider-separator">
                  </span>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-f745561 elementor-widget__width-initial elementor-widget elementor-widget-heading"
                 data-id="f745561" data-element_type="widget" data-widget_type="heading.default">
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  Unlocking the Potential of STEM Excellence
                </h3>
              </div>
            </div>
            <div className="elementor-element elementor-element-2f9c16f elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-widget elementor-widget-text-editor"
                 data-id="2f9c16f" data-element_type="widget"
                 data-widget_type="text-editor.default">
              <div className="elementor-widget-container">
                <p>
                  At Digital Rocket Science, we offer a range of specialized services designed
                  to empower educators, institutions, and organizations in the pursuit of
                  excellence in STEM education and research. Our consulting, professional
                  services, and partnership opportunities are tailored to meet the unique
                  needs of your institution or project. Whether you seek guidance on
                  implementing cutting-edge STEM solutions, require expert assistance in
                  curriculum development, or wish to explore collaborative partnerships, our
                  team is dedicated to advancing STEM education. Discover how our services can
                  elevate your initiatives and drive innovation in the world of science,
                  technology, engineering, and math.
                </p>
              </div>
            </div>
            <div className="elementor-element elementor-element-89292c7 elementor-widget elementor-widget-button"
                 data-id="89292c7" data-element_type="widget" data-widget_type="button.default">
              <div className="elementor-widget-container">
                <div className="elementor-button-wrapper">
                  <a className="elementor-button elementor-button-link elementor-size-sm"
                     href="#contact-us">
                    <span className="elementor-button-content-wrapper">
                      <span className="elementor-button-text">
                        Explore Our Services
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
